<template>
    <div class="low-balance-wrapper" v-loading="isLoading">
        <div class="text-center d-flex align-items-center justify-content-center">
            <h5 v-if="lowBalaceData && lowBalaceData.length > 0">
                <strong>{{lowBalaceData.length}} LOW BALANCE USER{{lowBalaceData.length > 1 ? "S" : ""}}</strong>
            </h5>
            <h5 v-else>
                <strong>NO LOW BALANCE DETECTED</strong>
            </h5>
            <el-button type="text" class="ml-3 mb-1 p-0" size="large"
                @click="getLowBalanceReport()">
                <i v-if="!isLoading" class="el-icon-refresh fa-2x"></i>
                <i v-else class="el-icon-loading fa-2x"></i>
            </el-button>
        </div>
        <div v-if="lowBalaceData && lowBalaceData.length > 0">
            <VuePerfectScrollbar class="d-flex mr-2"
                :settings="{ suppressScrollX: false }">
                <div class="w-100">
                    <div class="low-balance-table mt-2">
                        <div class="low-balance-row low-balance-header low-balance-blue">
                            <div class="low-balance-cell">
                                Username
                            </div>
                            <div class="low-balance-cell text-right">
                                Balance
                            </div>
                            <div class="low-balance-cell text-right">
                                Estimated spent
                            </div>
                            <div class="low-balance-cell text-right">
                                Speed
                            </div>
                            <div class="low-balance-cell text-right">
                                Remaining
                            </div>
                            <div class="low-balance-cell text-right">
                                Remaining time
                            </div>
                            <div class="low-balance-cell text-right">
                                Expected shutdown
                            </div>
                            <div class="low-balance-cell text-right">
                                
                            </div>
                        </div>

                        <div class="low-balance-row"
                            v-for="(lowBalaceDataItem, lowBalaceDataItemIndex) in lowBalaceData"
                            :key="lowBalaceDataItem.user_id"
                            v-bind:class="{ 'low-balance-warning': lowBalaceDataItem.warning }">
                            <div class="low-balance-cell" data-title="Username">
                                <a href="javascript:void(0);"
                                    @click="$getUserDetailInfo(lowBalaceDataItem.user_id)">
                                    {{ lowBalaceDataItemIndex + 1 }}. <i class="fas fa-user"></i> <strong>{{lowBalaceDataItem.user_name}}</strong>
                                </a>
                            </div>
                            <div class="low-balance-cell text-right" data-title="Balance">
                                <strong>{{lowBalaceDataItem.balance | numFormat('0,0[.][0000]')}}</strong>
                                <img :src="$appPointIcon" width="15" height="15" class="ml-1 icon-point-bordered">
                            </div>
                            <div class="low-balance-cell text-right" data-title="Estimated spent">
                                <strong>{{lowBalaceDataItem.temp_money | numFormat('0,0[.][0000]')}}</strong>
                                <img :src="$appPointIcon" width="15" height="15" class="ml-1 icon-point-bordered">
                            </div>
                            <div class="low-balance-cell text-right" data-title="Speed">
                                <strong>{{lowBalaceDataItem.spentPerSecond*3600 | numFormat('0,0[.][0000]')}}</strong>
                                <img :src="$appPointIcon" width="15" height="15" class="ml-1 icon-point-bordered">
                                <span>/hour</span>
                            </div>
                            <div class="low-balance-cell text-right" data-title="Remaining">
                                <strong>{{lowBalaceDataItem.remain_money | numFormat('0,0[.][0000]')}}</strong>
                                <img :src="$appPointIcon" width="15" height="15" class="ml-1 icon-point-bordered">
                            </div>
                            <div class="low-balance-cell text-right" data-title="Remaining time">
                                <div v-if="lowBalaceDataItem.remain_time_in_seconds_calc && lowBalaceDataItem.remain_time_in_seconds_calc >= 0">
                                    <div><small>{{lowBalaceDataItem.remain_time_in_seconds_calc | secondDuration }}</small></div>
                                    <div class="pr-3 mb-2" style="transform: scaleX(-1);">
                                        <el-progress :percentage="(lowBalaceDataItem.remain_money/lowBalaceDataItem.balance)*100" :show-text="false"
                                            define-back-color="#d0d0d0"></el-progress>
                                    </div>
                                </div>
                                <span v-if="lowBalaceDataItem.remain_time_in_seconds_calc && lowBalaceDataItem.remain_time_in_seconds_calc < 0">
                                    Will be shutdown
                                </span>
                            </div>
                            <div class="low-balance-cell text-right" data-title="Expected shutdown">
                                <span v-if="lowBalaceDataItem.estimated_shutdown_time">
                                    {{lowBalaceDataItem.estimated_shutdown_time | pretyDateUtc }}
                                </span>
                            </div>
                            <div class="low-balance-cell text-right" data-title="Warning">
                                <span class="blink_me" v-if="lowBalaceDataItem.warning">
                                    <i class="el-icon-warning text-danger"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
import vmMachinesApi from '@/api/gpuHub/vmMachines';
export default {
    data() {
        return {
            isLoading: false,
            lowBalaceData: [],
            intervalLoad: null,
            lastUpdate: null,
        };
    },
    mounted() {
        this.getLowBalanceReport();
        this.intervalLoad = setInterval(() => {
            this.getLowBalanceReport(false);
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.intervalLoad);
    },
    methods: {
        getLowBalanceReport(isShowLoading = true) {
            if(isShowLoading) this.isLoading = true;
            vmMachinesApi.getLowBalanceReport().then((response) => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.lastUpdate = new Date();
                    this.lowBalaceData = response.data.data;
                }
                else {
                    //this.lowBalaceData = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
                if(isShowLoading) this.isLoading = false;
            }).catch(error => {
                console.error(error);
                //this.lowBalaceData = [];
                if(isShowLoading) this.isLoading = false;
            });
        }
    }
}
</script>

<style>
.low-balance-wrapper {
  margin: 0 auto;
  padding: 5px;
  width: 100%;
}
.low-balance-table {
  margin: 0 0 0 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
.low-balance-row {
  display: table-row;
  background: #f6f6f6;
  white-space: nowrap;
}
.low-balance-row:nth-of-type(odd) {
  background: #e9e9e9;
}
.low-balance-row.low-balance-header {
  font-weight: 900;
  color: #ffffff;
  background: #ea6153;
}
.low-balance-row.low-balance-green {
  background: #27ae60;
}
.low-balance-row.low-balance-blue {
  background: #2980b9;
}
.low-balance-row.low-balance-warning {
  background: #fdb7b7;
}

.low-balance-cell {
    padding: 6px 12px;
    display: table-cell;
    font-size: 1.2rem;
    vertical-align: middle;
}
.icon-point-bordered{
    -webkit-filter: drop-shadow(2px 2px 0 #fff)
                  drop-shadow(-2px -2px 0 #fff);
  filter: drop-shadow(2px 2px 0 #fff) 
          drop-shadow(-2px -2px 0 #fff);
}
</style>